body.remote-profile {
  padding-top: 120px;
}

body.remote-profile.masquerade-mode {
  padding-top: 105px;
}

@media screen {
  body {
    padding-top: 60px;
  }

  body.masquerade-mode {
    padding-top: 50px;
  }
}

body.remote-profile #remote-header-menu {
  top: 50px;
}

body.remote-profile #remote-header-menu #remote-navbar {
  float: none;
}

body.remote-profile #remote-header-menu #remote-navbar li {
  display: block;
}

@media (min-width: 768px) {
  body {
    padding-top: 68px;
  }

  body.masquerade-mode {
    padding-top: 54px;
  }

  body.remote-profile {
    padding-top: 130px;
  }

  body.remote-profile.masquerade-mode {
    padding-top: 100px;
  }
}

@media (min-width: 992px) {
  body.remote-profile #remote-header-menu {
    top: 54px;
  }

  body.remote-profile #remote-header-menu #remote-navbar {
    float: left;
  }

  body.remote-profile #remote-header-menu #remote-navbar li {
    display: block;
  }
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

#header-menu {
  z-index: 9;
  background-color: var(--color-white);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0;
  margin-bottom: 1px;
}

.wrapper-top,
.wrapper-bottom {
  background-color: var(--color-white);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  margin: 0;
}

.wrapper-top-inner {
  padding: 0 35px;
}

.wrapper-bottom-inner {
  padding: 5px 35px 5px;
}

.wrapper-top {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.12);
  margin-top: 15px;
}

.wrapper-top > .row {
  margin-left: 0;
  margin-right: 0;
}

.wrapper-bottom {
  background-color: var(--color-gray-100);
  border-radius: 0 0 5px 5px;
}

.wrapper {
  background-color: var(--color-white);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  margin-bottom: 10px;
}

.wrapper-with-header .header-fixed {
  background-color: var(--body-background);
}

.wrapper > h1 {
  margin: 10px 0;
  padding: 10px 20px;
}

.wrapper .row-bordered,
.wrapper > .row,
.wrapper > .row > .row {
  border-bottom: 1px solid var(--color-gray-300);
  margin: 0;
  padding: 10px;
}

.wrapper > .row > .row {
  margin-left: -20px;
  margin-right: -20px;
}

.wrapper .row-bordered {
  padding-left: 0;
  padding-right: 0;
}

.wrapper .row-bordered:last-child,
.wrapper > .row:last-child,
.wrapper > .row > .row:last-child {
  border-bottom-style: none;
}

.wrapper > .row > .row:last-child {
  border-bottom: 0;
}

#contextual-menu-background {
  background-color: var(--color-black);
  display: none;
  height: 100%;
  opacity: 0.8;
  position: fixed;
  width: 100%;
  z-index: 9999;
}

#contextual-menu-background-close {
  bottom: 10%;
  left: 45%;
  position: fixed;
  z-index: 9999;
}

hr {
  margin: 3px 0 3px 0;
}

.has-border-bottom {
  border-bottom: 1px solid var(--color-gray-300);
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.has-border-bottom.condensed {
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.wrapper-highlighted {
  border: 2px solid var(--color-primary);
}

#phone-layout {
  font-size: 15px;
}

#phone-layout .a {
  display: flex;
  width: 100%;
}

/* stylelint-disable-next-line no-descending-specificity */
#phone-layout .a > li {
  display: table-cell;
  flex: 1;
  text-align: center;
}

#phone-layout .v-align {
  margin-top: 10px;
}

#desktop-layout {
  padding-left: 20px;
  padding-right: 20px;
}

#phone-kz-logo img {
  max-width: 60px;
}

.flexbox .vertical-center {
  align-items: center;
  display: flex;
}

@media (max-width: 480px) {
  .flexbox .vertical-center-xs {
    align-items: center;
    display: flex;
  }
}
