/* stylelint-disable custom-property-empty-line-before */
:root {
  /* Breakpoints */
  --screen-xs: 480px;
  --screen-sm: 768px;
  --screen-sm-min: var(--screen-sm);
  --screen-md: 992px;
  --screen-md-min: var(--screen-md);
  --screen-lg: 1200px;
  --screen-lg-min: var(--screen-lg);
  --screen-xs-max: calc(var(--screen-sm-min) - 1);
  --screen-sm-max: calc(var(--screen-md-min) - 1);
  --screen-md-max: calc(var(--screen-lg-min) - 1);

  --rem-base: 1rem;
  --base-font-size: 88.88%;
  --size-gutter: 16px;
  --size-increment-step: 0.25em;

  --body-font-weight: 300;
  --body-font-style: normal;
  --base-line-height: 140%;
  --cursor-default-value: 'default';

  --heading-font-family: 'DM Sans', sans-serif;
  --body-font-family: 'DM Sans', sans-serif;

  --button-border-radius: 8px;
  --button-border-radius-sm: 4px;
  --button-border-radius-lg: 16px;
  --avatar-border-radius: 50%;

  --border-radius: 0;
  --border-color: var(--color-gray);
  --border-style-dotted: 1px dashed var(--color-info);
  --border-style-default: 1px solid var(--color-info);

  --line-height-base: 1.428571429;
  --padding-base-vertical: 6px;
  --padding-base-horizontal: 12px;
  --line-height-computed: calc(var(--rem-base) * var(--line-height-base));
  --input-height-base: calc(var(--line-height-computed) + (var(--padding-base-vertical) * 2) + 2);

  /* Colors */
  --color-primary: #001e33;
  --color-primary-100: #c0f1ec;
  --color-primary-200: #55d9cc;
  --color-primary-300: #32bdb0;
  --color-primary-400: #1fb2a3;
  --color-primary-500: #259eaa;
  --color-primary-600: #1f8a95;
  --color-primary-700: #1f5b73;
  --color-primary-800: #103b59;
  --color-primary-900: #002d4d;

  /* We are duplicating info as primary for now */
  --color-info: #001e33;
  --color-info-100: #c0f1ec;
  --color-info-200: #55d9cc;
  --color-info-300: #32bdb0;
  --color-info-400: #1fb2a3;
  --color-info-500: #259eaa;
  --color-info-600: #1f8a95;
  --color-info-700: #1f5b73;
  --color-info-800: #103b59;
  --color-info-900: #002d4d;

  --color-danger: #d00000;
  --color-danger-100: #ffe5e5;
  --color-danger-200: #ffb3b3;
  --color-danger-300: #ff8080;
  --color-danger-400: #ff4d4d;
  --color-danger-500: #ff1a1a;
  --color-danger-600: #e60000;
  --color-danger-700: #b30000;
  --color-danger-800: #800000;
  --color-danger-900: #4d0000;

  --color-success: #1d8666;
  --color-success-100: #eafaf5;
  --color-success-200: #c0f1e2;
  --color-success-300: #96e8cf;
  --color-success-400: #6cdfbc;
  --color-success-500: #42d6a9;
  --color-success-600: #29bd90;
  --color-success-700: #209370;
  --color-success-800: #176950;
  --color-success-900: #0e3f30;

  --color-warning: #ff6d00;
  --color-warning-100: #fff0e5;
  --color-warning-200: #ffd3b3;
  --color-warning-300: #ffb680;
  --color-warning-400: #ff994d;
  --color-warning-500: #ff7c1a;
  --color-warning-600: #e66200;
  --color-warning-700: #b34c00;
  --color-warning-800: #803700;
  --color-warning-900: #4d2100;

  --color-gray: #4f4f50;
  --color-gray-100: #f2f2f2;
  --color-gray-200: #d9d9d9;
  --color-gray-300: #bfbfc0;
  --color-gray-400: #a5a5a6;
  --color-gray-500: #8c8c8d;
  --color-gray-600: #727273;
  --color-gray-700: #59595a;
  --color-gray-800: #3f3f40;
  --color-gray-900: #262626;

  --color-white: hsl(0, 0%, 100%);
  --color-black: hsl(0, 0%, 0%);

  --color-form-field-focus: hsla(65, 100%, 87%, 0.64);
  --color-box-shadow: hsla(0, 0%, 0%, 0.12);

  --body-background: hsl(0, 0%, 90%);
  --font-color: var(--color-gray-900);
  --font-hover-color: var(--color-gray-700);
  --theme-button-font-color: hsl(0, 0%, 100%);
  --link-color: var(--color-info-800);
}
/* stylelint-enable custom-property-empty-line-before */
