.card-header {
  background-color: var(--color-primary);
  border-top-left-radius: var(--button-border-radius-sm);
  border-top-right-radius: var(--button-border-radius-sm);
  padding: 0.4em 0.64em;
}

.card-header h4,
.card-header p[class^="heading"] {
  display: inline-block;
}

.card-header p {
  line-height: 1;
}

.card-content p {
  padding: 5px 15px;
}

.card-content .row p:not(.box) {
  padding: 0.32em 0;
}

.sidebar-card .advanced-search-form .form-group {
  padding: 0;
}

.sidebar-card .advanced-search-form .form-group label {
  margin-top: 0;
  padding-top: 0;
}

.sidebar-card .advanced-search-form .form-group .form-control {
  margin: 0 0 10px 0;
  padding: 0;
}

.sidebar .stick {
  position: fixed;
  width: 23.83%;
  z-index: 1000;
}

.card-header.card-info {
  background-color: var(--color-info);
}

.card-header.card-warning {
  background-color: var(--color-warning);
}

.card-header.card-success {
  background-color: var(--color-success);
}

.card-header.card-danger {
  background-color: var(--color-danger);
}

.card-header h4,
.card-header p[class^="heading"],
.card-header a {
  color: var(--color-white);
  font-size: 0.9rem;
}

.card-content p.item-title {
  color: var(--color-info);
  padding: 0;
}

.card-content .col-margins {
  padding: 1em 2em;
}

.card-content .card-subheader {
  background-color: var(--color-gray-200);
  font-size: 12px;
}

.card-content ul:not(.list-inline, .list-unstyled) {
  padding: 0 0.64em;
}

.card-header a:active,
.card-header a:focus,
.card-header a:hover {
  text-decoration: none;
}
