.item-list {
  list-style: none inside none;
  margin-left: 0;
}

.item-list .item {
  padding: 0.68em;
}

.item-list-bordered .item {
  border-bottom: 1px solid var(--color-gray-300);
}

.item-list .item-new {
  background-color: var(--color-gray-200);
}

.item-list .item.item-icon-left {
  padding-left: 35px;
}

.item-list-bordered .item:last-child {
  border-bottom: 0;
}

.list-icon-actions {
  padding-top: 10px;
}

.list-icon-actions a {
  cursor: pointer;
}

.list-icon-actions > li i {
  font-size: 1.875rem;
}

.list-icon-actions > li i.icon-trash {
  color: var(--color-danger);
}

.list-icon-actions > li i.icon-down-open-big {
  color: var(--color-gray-300);
}

.list-icon-actions > li i.icon-right-open-big {
  color: var(--color-gray-400);
}

.list-spaced li {
  margin-left: 0.4em;
  margin-right: 0.4em;
}

.list-condensed li {
  line-height: 100%;
  margin-bottom: 0;
  margin-top: 0;
}

.list-tags li {
  margin-bottom: 0.4em;
  margin-top: 0.4em;
}

.list-unstyled {
  margin-left: 0;
  padding-left: 0;
}

.list-unstyled li:first-child {
  padding-left: 0;
}

.list-no-right-padding li:last-of-type {
  padding-right: 0;
}

.button-list {
  text-align: right;
}

.button-list div:not(.btn-group) > ul > li:last-child {
  padding-right: 0;
}

.button-list > a.btn-icon-circle {
  margin: 0;
}

@media (min-width: 768px) {
  .list-columns-2 {
    column-count: 2;
  }

  .list-columns-3 {
    column-count: 3;
  }

  .list-columns-4 {
    column-count: 4;
  }
}
