.title-select {
  font-size: 1.5rem;
  padding: 10px 0;
}

.title-select a,
.title-select .caret {
  color: var(--color-primary);
}

.title-select li > a {
  padding: 10px;
}

.widget-categories .panel-title a {
  font-weight: 600;
}

.widget-categories .panel-title::before,
.widget-categories .panel-title a {
  font-size: 0.98rem;
}

.title-select li > a:hover,
.title-select li > a:active,
.title-select li > a:focus {
  text-decoration: none;
}

.title-select li.active > a {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.widget-categories .panel-title {
  color: var(--color-primary);
}

.widget-categories .panel {
  box-shadow: none;
}

.widget-categories .panel > .panel-heading {
  background-color: transparent;
  padding-left: 0;
}

.widget-categories .panel-title::before {
  content: '\e81e';
  display: inline-block;
  font-family: 'epf';
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1em;
  margin-left: 0.2em;
  margin-right: 0.2em;
  text-align: center;
  text-decoration: inherit;
  text-transform: none;
  width: 1em;
}

.widget-categories .panel-open .panel-title::before {
  content: '\e81c';
}

.dashboard .widget .panel-heading {
  background-color: var(--color-primary);
  color: var(--color-white);
  padding: 0;
}

.dashboard .widget .panel-heading .panel-title {
  color: var(--color-white);
  padding: 10px 15px;
}

.dashboard .widget .panel-heading .panel-title a {
  color: var(--color-white);
}

.dashboard .widget .panel-body {
  padding-bottom: 5px;
  padding-top: 5px;
}

/* Widgets */
.dashboard .widget .profile-widget-avatar {
  text-align: center;
}

.dashboard .widget .profile-widget-avatar .pwa_bg {
  background-color: var(--color-primary);
  display: block;
  height: 60px;
  margin: -5px -15px;
}

.dashboard .widget .profile-widget-avatar .avatar {
  margin-top: -60px;
}

/* Images should not overflow the editor when viewed */
.dashboard .widget .panel-body .readonly p img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
}

/*
  The quill editor adds p tags with <br />s which causes extra margins for content.
  First we add a top/bottom padding to the content.
  And then we remove the individual paragraph margins.
*/

.dashboard .widget .panel-body .readonly {
  padding: 0.88em 0;
}

.dashboard .widget .panel-body .readonly p {
  margin: 0;
}

@media (--tablet-viewport-min) {
@media (min-width: 768px) {
  .title-select {
    font-size: 2.25rem;
  }
}
}
