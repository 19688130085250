.alignleft { text-align: left; }
.alignright { text-align: right; }
.aligncenter { text-align: center; }
.alignjustify { text-align: justify; }

.link-no-underline:hover,
.link-no-underline:focus {
  text-decoration: none;
}

.entry-title {
  padding: 3px 0;
}

.heading-1 { font-size: 2.25rem; }
.heading-2 { font-size: 1.875rem; }
.heading-3 { font-size: 1.5rem; }
.heading-4 { font-size: 1.25rem; }
.heading-5 { font-size: 1.125rem; }

a.disabled {
  color: var(--color-gray);
}

a.disabled:hover,
a.disabled:focus {
  cursor: not-allowed;
}

@media (min-width: 768px) {
  .text-left-sm {
    text-align: left;
  }

  .text-right-sm {
    text-align: right;
  }
}

@media (max-width: 768px) {
  .truncate-xs-200 {
    display: inline-block;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
