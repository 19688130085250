/* stylelint-disable selector-list-comma-newline-after, rule-empty-line-before */

/* Tops */
.mt-0 { margin-top: 0 !important; }
.mt-1 { margin-top: var(--size-increment-step) !important; }
.mt-2 { margin-top: calc(var(--size-increment-step) * 2) !important; }
.mt-3 { margin-top: calc(var(--size-increment-step) * 3) !important; }
.mt-4 { margin-top: calc(var(--size-increment-step) * 4) !important; }
.mt-5 { margin-top: calc(var(--size-increment-step) * 5) !important; }
.mt-6 { margin-top: calc(var(--size-increment-step) * 6) !important; }
.mt-7 { margin-top: calc(var(--size-increment-step) * 7) !important; }
.mt-8 { margin-top: calc(var(--size-increment-step) * 8) !important; }
.mt-9 { margin-top: calc(var(--size-increment-step) * 9) !important; }
.mt-10 { margin-top: calc(var(--size-increment-step) * 10) !important; }

.pt-0 { padding-top: 0 !important; }
.pt-1 { padding-top: var(--size-increment-step) !important; }
.pt-2 { padding-top: calc(var(--size-increment-step) * 2) !important; }
.pt-3 { padding-top: calc(var(--size-increment-step) * 3) !important; }
.pt-4 { padding-top: calc(var(--size-increment-step) * 4) !important; }
.pt-5 { padding-top: calc(var(--size-increment-step) * 5) !important; }
.pt-6 { padding-top: calc(var(--size-increment-step) * 6) !important; }
.pt-7 { padding-top: calc(var(--size-increment-step) * 7) !important; }
.pt-8 { padding-top: calc(var(--size-increment-step) * 8) !important; }
.pt-9 { padding-top: calc(var(--size-increment-step) * 9) !important; }
.pt-10 { padding-top: calc(var(--size-increment-step) * 10) !important; }

/* Bottoms */
.mb-0 { margin-bottom: 0 !important; }
.mb-1 { margin-bottom: var(--size-increment-step) !important; }
.mb-2 { margin-bottom: calc(var(--size-increment-step) * 2) !important; }
.mb-3 { margin-bottom: calc(var(--size-increment-step) * 3) !important; }
.mb-4 { margin-bottom: calc(var(--size-increment-step) * 4) !important; }
.mb-5 { margin-bottom: calc(var(--size-increment-step) * 5) !important; }
.mb-6 { margin-bottom: calc(var(--size-increment-step) * 6) !important; }
.mb-7 { margin-bottom: calc(var(--size-increment-step) * 7) !important; }
.mb-8 { margin-bottom: calc(var(--size-increment-step) * 8) !important; }
.mb-9 { margin-bottom: calc(var(--size-increment-step) * 9) !important; }
.mb-10 { margin-bottom: calc(var(--size-increment-step) * 10) !important; }

.pb-0 { padding-bottom: 0 !important; }
.pb-1 { padding-bottom: var(--size-increment-step) !important; }
.pb-2 { padding-bottom: calc(var(--size-increment-step) * 2) !important; }
.pb-3 { padding-bottom: calc(var(--size-increment-step) * 3) !important; }
.pb-4 { padding-bottom: calc(var(--size-increment-step) * 4) !important; }
.pb-5 { padding-bottom: calc(var(--size-increment-step) * 5) !important; }
.pb-6 { padding-bottom: calc(var(--size-increment-step) * 6) !important; }
.pb-7 { padding-bottom: calc(var(--size-increment-step) * 7) !important; }
.pb-8 { padding-bottom: calc(var(--size-increment-step) * 8) !important; }
.pb-9 { padding-bottom: calc(var(--size-increment-step) * 9) !important; }
.pb-10 { padding-bottom: calc(var(--size-increment-step) * 10) !important; }

/* Lefts */
.ml-0 { margin-left: 0 !important; }
.ml-1 { margin-left: var(--size-increment-step) !important; }
.ml-2 { margin-left: calc(var(--size-increment-step) * 2) !important; }
.ml-3 { margin-left: calc(var(--size-increment-step) * 3) !important; }
.ml-4 { margin-left: calc(var(--size-increment-step) * 4) !important; }
.ml-5 { margin-left: calc(var(--size-increment-step) * 5) !important; }
.ml-6 { margin-left: calc(var(--size-increment-step) * 6) !important; }
.ml-7 { margin-left: calc(var(--size-increment-step) * 7) !important; }
.ml-8 { margin-left: calc(var(--size-increment-step) * 8) !important; }
.ml-9 { margin-left: calc(var(--size-increment-step) * 9) !important; }
.ml-10 { margin-left: calc(var(--size-increment-step) * 10) !important; }

.pl-0 { padding-left: 0 !important; }
.pl-1 { padding-left: var(--size-increment-step) !important; }
.pl-2 { padding-left: calc(var(--size-increment-step) * 2) !important; }
.pl-3 { padding-left: calc(var(--size-increment-step) * 3) !important; }
.pl-4 { padding-left: calc(var(--size-increment-step) * 4) !important; }
.pl-5 { padding-left: calc(var(--size-increment-step) * 5) !important; }
.pl-6 { padding-left: calc(var(--size-increment-step) * 6) !important; }
.pl-7 { padding-left: calc(var(--size-increment-step) * 7) !important; }
.pl-8 { padding-left: calc(var(--size-increment-step) * 8) !important; }
.pl-9 { padding-left: calc(var(--size-increment-step) * 9) !important; }
.pl-10 { padding-left: calc(var(--size-increment-step) * 10) !important; }

/* Rights */
.mr-0 { margin-right: 0 !important; }
.mr-1 { margin-right: var(--size-increment-step) !important; }
.mr-2 { margin-right: calc(var(--size-increment-step) * 2) !important; }
.mr-3 { margin-right: calc(var(--size-increment-step) * 3) !important; }
.mr-4 { margin-right: calc(var(--size-increment-step) * 4) !important; }
.mr-5 { margin-right: calc(var(--size-increment-step) * 5) !important; }
.mr-6 { margin-right: calc(var(--size-increment-step) * 6) !important; }
.mr-7 { margin-right: calc(var(--size-increment-step) * 7) !important; }
.mr-8 { margin-right: calc(var(--size-increment-step) * 8) !important; }
.mr-9 { margin-right: calc(var(--size-increment-step) * 9) !important; }
.mr-10 { margin-right: calc(var(--size-increment-step) * 10) !important; }

.pr-0 { padding-right: 0 !important; }
.pr-1 { padding-right: var(--size-increment-step) !important; }
.pr-2 { padding-right: calc(var(--size-increment-step) * 2) !important; }
.pr-3 { padding-right: calc(var(--size-increment-step) * 3) !important; }
.pr-4 { padding-right: calc(var(--size-increment-step) * 4) !important; }
.pr-5 { padding-right: calc(var(--size-increment-step) * 5) !important; }
.pr-6 { padding-right: calc(var(--size-increment-step) * 6) !important; }
.pr-7 { padding-right: calc(var(--size-increment-step) * 7) !important; }
.pr-8 { padding-right: calc(var(--size-increment-step) * 8) !important; }
.pr-9 { padding-right: calc(var(--size-increment-step) * 9) !important; }
.pr-10 { padding-right: calc(var(--size-increment-step) * 10) !important; }

/* Horizontals */
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.mx-1 {
  margin-left: var(--size-increment-step) !important;
  margin-right: var(--size-increment-step) !important;
}
.mx-2 {
  margin-left: calc(var(--size-increment-step) * 2) !important;
  margin-right: calc(var(--size-increment-step) * 2) !important;
}
.mx-3 {
  margin-left: calc(var(--size-increment-step) * 3) !important;
  margin-right: calc(var(--size-increment-step) * 3) !important;
}
.mx-4 {
  margin-left: calc(var(--size-increment-step) * 4) !important;
  margin-right: calc(var(--size-increment-step) * 4) !important;
}
.mx-5 {
  margin-left: calc(var(--size-increment-step) * 5) !important;
  margin-right: calc(var(--size-increment-step) * 5) !important;
}
.mx-6 {
  margin-left: calc(var(--size-increment-step) * 6) !important;
  margin-right: calc(var(--size-increment-step) * 6) !important;
}
.mx-7 {
  margin-left: calc(var(--size-increment-step) * 7) !important;
  margin-right: calc(var(--size-increment-step) * 7) !important;
}
.mx-8 {
  margin-left: calc(var(--size-increment-step) * 8) !important;
  margin-right: calc(var(--size-increment-step) * 8) !important;
}
.mx-9 {
  margin-left: calc(var(--size-increment-step) * 9) !important;
  margin-right: calc(var(--size-increment-step) * 9) !important;
}
.mx-10 {
  margin-left: calc(var(--size-increment-step) * 10) !important;
  margin-right: calc(var(--size-increment-step) * 10) !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.px-1 {
  padding-left: var(--size-increment-step) !important;
  padding-right: var(--size-increment-step) !important;
}
.px-2 {
  padding-left: calc(var(--size-increment-step) * 2) !important;
  padding-right: calc(var(--size-increment-step) * 2) !important;
}
.px-3 {
  padding-left: calc(var(--size-increment-step) * 3) !important;
  padding-right: calc(var(--size-increment-step) * 3) !important;
}
.px-4 {
  padding-left: calc(var(--size-increment-step) * 4) !important;
  padding-right: calc(var(--size-increment-step) * 4) !important;
}
.px-5 {
  padding-left: calc(var(--size-increment-step) * 5) !important;
  padding-right: calc(var(--size-increment-step) * 5) !important;
}
.px-6 {
  padding-left: calc(var(--size-increment-step) * 6) !important;
  padding-right: calc(var(--size-increment-step) * 6) !important;
}
.px-7 {
  padding-left: calc(var(--size-increment-step) * 7) !important;
  padding-right: calc(var(--size-increment-step) * 7) !important;
}
.px-8 {
  padding-left: calc(var(--size-increment-step) * 8) !important;
  padding-right: calc(var(--size-increment-step) * 8) !important;
}
.px-9 {
  padding-left: calc(var(--size-increment-step) * 9) !important;
  padding-right: calc(var(--size-increment-step) * 9) !important;
}
.px-10 {
  padding-left: calc(var(--size-increment-step) * 10) !important;
  padding-right: calc(var(--size-increment-step) * 10) !important;
}

/* Verticals */
.my-0 {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
.my-1 {
  margin-bottom: var(--size-increment-step) !important;
  margin-top: var(--size-increment-step) !important;
}
.my-2 {
  margin-bottom: calc(var(--size-increment-step) * 2) !important;
  margin-top: calc(var(--size-increment-step) * 2) !important;
}
.my-3 {
  margin-bottom: calc(var(--size-increment-step) * 3) !important;
  margin-top: calc(var(--size-increment-step) * 3) !important;
}
.my-4 {
  margin-bottom: calc(var(--size-increment-step) * 4) !important;
  margin-top: calc(var(--size-increment-step) * 4) !important;
}
.my-5 {
  margin-bottom: calc(var(--size-increment-step) * 5) !important;
  margin-top: calc(var(--size-increment-step) * 5) !important;
}
.my-6 {
  margin-bottom: calc(var(--size-increment-step) * 6) !important;
  margin-top: calc(var(--size-increment-step) * 6) !important;
}
.my-7 {
  margin-bottom: calc(var(--size-increment-step) * 7) !important;
  margin-top: calc(var(--size-increment-step) * 7) !important;
}
.my-8 {
  margin-bottom: calc(var(--size-increment-step) * 8) !important;
  margin-top: calc(var(--size-increment-step) * 8) !important;
}
.my-9 {
  margin-bottom: calc(var(--size-increment-step) * 9) !important;
  margin-top: calc(var(--size-increment-step) * 9) !important;
}
.my-10 {
  margin-bottom: calc(var(--size-increment-step) * 10) !important;
  margin-top: calc(var(--size-increment-step) * 10) !important;
}

.py-0 {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.py-1 {
  padding-bottom: var(--size-increment-step) !important;
  padding-top: var(--size-increment-step) !important;
}
.py-2 {
  padding-bottom: calc(var(--size-increment-step) * 2) !important;
  padding-top: calc(var(--size-increment-step) * 2) !important;
}
.py-3 {
  padding-bottom: calc(var(--size-increment-step) * 3) !important;
  padding-top: calc(var(--size-increment-step) * 3) !important;
}
.py-4 {
  padding-bottom: calc(var(--size-increment-step) * 4) !important;
  padding-top: calc(var(--size-increment-step) * 4) !important;
}
.py-5 {
  padding-bottom: calc(var(--size-increment-step) * 5) !important;
  padding-top: calc(var(--size-increment-step) * 5) !important;
}
.py-6 {
  padding-bottom: calc(var(--size-increment-step) * 6) !important;
  padding-top: calc(var(--size-increment-step) * 6) !important;
}
.py-7 {
  padding-bottom: calc(var(--size-increment-step) * 7) !important;
  padding-top: calc(var(--size-increment-step) * 7) !important;
}
.py-8 {
  padding-bottom: calc(var(--size-increment-step) * 8) !important;
  padding-top: calc(var(--size-increment-step) * 8) !important;
}
.py-9 {
  padding-bottom: calc(var(--size-increment-step) * 9) !important;
  padding-top: calc(var(--size-increment-step) * 9) !important;
}
.py-10 {
  padding-bottom: calc(var(--size-increment-step) * 10) !important;
  padding-top: calc(var(--size-increment-step) * 10) !important;
}

/* All */
.m-0 { margin: 0 !important; }
.m-1 { margin: var(--size-increment-step) !important; }
.m-2 { margin: calc(var(--size-increment-step) * 2) !important; }
.m-3 { margin: calc(var(--size-increment-step) * 3) !important; }
.m-4 { margin: calc(var(--size-increment-step) * 4) !important; }
.m-5 { margin: calc(var(--size-increment-step) * 5) !important; }
.m-6 { margin: calc(var(--size-increment-step) * 6) !important; }
.m-7 { margin: calc(var(--size-increment-step) * 7) !important; }
.m-8 { margin: calc(var(--size-increment-step) * 8) !important; }
.m-9 { margin: calc(var(--size-increment-step) * 9) !important; }
.m-10 { margin: calc(var(--size-increment-step) * 10) !important; }

.p-0 { padding: 0 !important; }
.p-1 { padding: var(--size-increment-step) !important; }
.p-2 { padding: calc(var(--size-increment-step) * 2) !important; }
.p-3 { padding: calc(var(--size-increment-step) * 3) !important; }
.p-4 { padding: calc(var(--size-increment-step) * 4) !important; }
.p-5 { padding: calc(var(--size-increment-step) * 5) !important; }
.p-6 { padding: calc(var(--size-increment-step) * 6) !important; }
.p-7 { padding: calc(var(--size-increment-step) * 7) !important; }
.p-8 { padding: calc(var(--size-increment-step) * 8) !important; }
.p-9 { padding: calc(var(--size-increment-step) * 9) !important; }
.p-10 { padding: calc(var(--size-increment-step) * 10) !important; }
/* stylelint-enable selector-list-comma-newline-after */

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
